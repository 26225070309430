export default
    {
        //(Please Do Not Remove The comma(,) after every variable)
        //Change The Website Template
        name :'Bob Kwan.',
        headerTagline: [//Line 1 For Header
                        'Hi 👋 You found me! I am Bob Kwan.',
                        //Line 2 For Header
                        'A Dad && Digital Contributor',
                        //Line 3 For Header
                        'From Hong Kong'
    ],
        //Contact Email
        contactEmail:'me@bobkwan.com',
        // Add Your About Text Here
        abouttext: "I've worked in the Digital Firm for almost a decade. Start from front-end developer, now as full-stack daddy👨‍👩‍👦. In the meanwhile, I'm really addicted to the sport, especially about the water ones. Also, the books(physicaly) make my life📚.\n Don't you think our planet gorgeous? Don't you think it is enjoyable surrounding such beautiful world with your family? So we need to keep the species diversity; environmental sustainability and human rights equality!\n Sorry for so much bu****its, all I want to say that 'Do the right things, Support the NGO, NOW'",
        aboutImage:'http://www.bobkwan.com/images/me.jpg',
       //Change This To Hide The Image of About Section (True Or False)
       ShowAboutImage:true,//true or false (Change Here)
       // Change Projects Here 
       projects:[
           {
            id: 1,
            title:'Gamejam 2015', //Project Title - Add Your Project Title Here
             service:'Gamer participant', // Add Your Service Type Here
            //Project Image - Add Your Project Image Here
             imageSrc:"http://www.bobkwan.com/images/icon_gamejam.jpg",
             //Project URL - Add Your Project Url Here
             url:'https://globalgamejam.org/2015/games/we-need-more-food'
            },
            {
                id: 2,
                title: 'Castelo Concepts',
                service: 'Website Development',
                imageSrc: "http://www.bobkwan.com/images/icon_castelo.jpg",
                url: 'http://www.casteloconcepts.com/'
            },
            { 
                id: 3,
                title: 'Diamania Jewelry',
                service: 'Website Development',
                imageSrc: "http://www.bobkwan.com/images/icon_diamania.jpg",
                url: 'https://www.diamania.com.hk/'
            },
            {
                id: 4,
                title: 'More',
                service: 'BrainStorm',
                imageSrc: "http://www.bobkwan.com/images/icon_more.jpg",
                url: 'mailto:me@bobkwan.com'
           }

                    /*

                    If You Want To Add More Project just Copy and Paste This At The End (Update the id Respectively)
                ,{
                id: 5,
                title: 'Project Five',
                service: 'Something Amazing',
                imageSrc: "",
                url: ''
            }
                */
        ],
        social: [
            // Add Or Remove The Link Accordingly
            {   name:'Github',
                url:'https://github.com/kwan838'},
            {
                name: 'Twitter',
                url: 'https://twitter.com/KwanTung'
            },
            {
                name: 'Linkedin',
                url: 'https://hk.linkedin.com/in/bobkwan'
            }

        ]
    }